<script>
  import userFSDocumentMixin from '@/mixins/userFSDocumentMixin.js';
  import { mapActions, mapMutations } from 'vuex';
  import AppBar from '@/components/general/app-bar/AppBar.vue';

  export default {
    mixins: [userFSDocumentMixin],
    name: 'change-name',
    data() {
      return {
        name: '',
        isChangingName: false,
      };
    },
    components: {
      AppBar,
    },
    async created() {
      await this.fetchUser();
      this.prefillNameField();
    },
    computed: {
      isNameDisabled() {
        return !this.name?.length;
      },
    },
    methods: {
      ...mapMutations(['setSuccessSnackbarMessage', 'setErrorSnackbarMessage']),
      ...mapActions('AuthModule', ['updateUserData']),
      async changeName() {
        this.isChangingName = true;

        try {
          await this.updateUserData({ uid: this.userFSDocument.uid, name: this.name });
          this.setSuccessSnackbarMessage('Your name has been successfully updated');
        } catch (error) {
          this.setErrorSnackbarMessage(error);
        } finally {
          this.isChangingName = false;
        }
      },
      prefillNameField() {
        this.name = this.userFSDocument.name;
      },
    },
  };
</script>
